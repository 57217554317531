div.gallery {
    margin: 5px;
    border: 1px solid #ccc;
    box-shadow: 10px 10px 5px lightblue;
    float: left;
    width: 280px;
}

div.gallery:hover {
    border: 1px solid #777;
}

div.gallery img {
    width: 100%;
    height: auto;
}
.marginGrid{
    margin: 24px;
    padding: 1vw;
}
div.name {
    padding: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #4CAF50;
}
div.desc {
    text-align: center;
    letter-spacing: 3px;
}

div.a.button {
    align: center;
}