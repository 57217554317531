@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.containerWrap {
  @apply container mx-auto max-w-4xl
}

:root {
  --primary: #bc4123;
  --secondary: #2b3452;
}
body {
  margin: 0;
  padding: 20px;
  font-family: "Poppins", serif;
  background: var(--secondary);
}
* {
  color: #fff;
  margin: 0;
}
p {
  margin: 20px 0;
}
button {
  border: 0;
  padding: 8px;
  border-radius: 4px;
  color: white;
  background: var(--primary);
  cursor: pointer;
}

/* navbar */
header nav {
  display: flex;
  gap: 16px;
  justify-content: end;
  max-width: 1200px;
  margin: 0 auto;
}
header nav h1 {
  margin-right: auto;
  border-bottom: 3px solid var(--primary);
}
header nav a{
  text-decoration: none;
  padding: 6px;
  border-radius: 4px;
}
header nav a.active {
  background: var(--primary);
}

/* page content */
main {
  /*max-width: 80vw;*/
  /*z-index: 0;*/
  margin: 40px auto;
}
input:focus {
  color: #ffffff;
  outline: 2px solid orange;
}

/* help layout */
.help-layout nav {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 30px 0;
}
.help-layout nav a{
  padding: 10px;
  border: 2px solid white;
  border-radius: 4px;
  text-decoration: none;
}
.help-layout nav a:hover{
  border-color: var(--primary);
}
.help-layout nav a.active, .active_navlink {
  background: var(--primary);
}

/* faq */
.faq .question {
  background: rgba(0,0,0,0.2);
  padding: 5px 20px;
  border-radius: 4px;
  margin: 20px 0;
}

/* contact form */
form {
  margin-top: 30px;
}
form input, form label span, form textarea {
  display: block;
}
form input, form textarea {
  margin-bottom: 30px;
  padding: 8px;
  border-radius: 4px;
  border: 0;
  width: 300px;
  color: var(--secondary);
}
form label span {
  margin-bottom: 10px;
}

img {
  width: 100%;

}

img:hover {
  transform: scale(1.25);
  transition: 0.5s all ease-in-out;
}


